"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LambdaExecutionTimeoutError = exports.LambdaLexingError = exports.LambdaSyntaxError = exports.purgeAstCache = exports.equal = exports.replace = exports.tokenize = exports.leftmostOutermostRedex = exports.toNormalForm = exports.eReduce = exports.bReduce = exports.getFreeVars = exports.renderAsChurchBoolean = exports.renderAsChurchNumeral = exports.renderExpression = exports.parseExtendedSyntax = exports.parseTerm = void 0;
// ---
// lol how do i even do named exports
var parser_1 = require("./parser");
Object.defineProperty(exports, "parseTerm", { enumerable: true, get: function () { return parser_1.parseTerm; } });
Object.defineProperty(exports, "parseExtendedSyntax", { enumerable: true, get: function () { return parser_1.parseExtendedSyntax; } });
var renderer_1 = require("./renderer");
Object.defineProperty(exports, "renderExpression", { enumerable: true, get: function () { return renderer_1.renderExpression; } });
var churchPrimitives_1 = require("./churchPrimitives");
Object.defineProperty(exports, "renderAsChurchNumeral", { enumerable: true, get: function () { return churchPrimitives_1.renderAsChurchNumeral; } });
Object.defineProperty(exports, "renderAsChurchBoolean", { enumerable: true, get: function () { return churchPrimitives_1.renderAsChurchBoolean; } });
var util_1 = require("./util");
Object.defineProperty(exports, "getFreeVars", { enumerable: true, get: function () { return util_1.getFreeVars; } });
Object.defineProperty(exports, "purgeAstCache", { enumerable: true, get: function () { return util_1.purgeAstCache; } });
var operations_1 = require("./operations");
Object.defineProperty(exports, "bReduce", { enumerable: true, get: function () { return operations_1.bReduce; } });
Object.defineProperty(exports, "eReduce", { enumerable: true, get: function () { return operations_1.eReduce; } });
Object.defineProperty(exports, "replace", { enumerable: true, get: function () { return operations_1.replace; } });
var normalize_1 = require("./normalize");
Object.defineProperty(exports, "toNormalForm", { enumerable: true, get: function () { return normalize_1.toNormalForm; } });
Object.defineProperty(exports, "leftmostOutermostRedex", { enumerable: true, get: function () { return normalize_1.leftmostOutermostRedex; } });
var lexer_1 = require("./lexer");
Object.defineProperty(exports, "tokenize", { enumerable: true, get: function () { return lexer_1.tokenize; } });
var equality_1 = require("./equality");
Object.defineProperty(exports, "equal", { enumerable: true, get: function () { return equality_1.equal; } });
var errors_1 = require("./errors");
Object.defineProperty(exports, "LambdaSyntaxError", { enumerable: true, get: function () { return errors_1.LambdaSyntaxError; } });
Object.defineProperty(exports, "LambdaLexingError", { enumerable: true, get: function () { return errors_1.LambdaLexingError; } });
Object.defineProperty(exports, "LambdaExecutionTimeoutError", { enumerable: true, get: function () { return errors_1.LambdaExecutionTimeoutError; } });
