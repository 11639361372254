"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokenize = void 0;
var errors_1 = require("./errors");
// idk i'm just guessing here. If you want something, just add it and i'll probs approve it
var validSingleChars = /[a-zα-κμ-ω+\!\-\|\&]/;
var validMultiChars = /[A-Z_]/;
function tokenize(str) {
    var tokenStream = [];
    for (var pos = 0; pos < str.length; pos++) {
        var nextChar = str.slice(pos, pos + 1);
        if (/\s/.test(nextChar)) { // skip whitespace.
            continue;
        }
        if (nextChar === 'λ') {
            tokenStream.push({
                type: 'lambda'
            });
        }
        else if (nextChar === '.') {
            tokenStream.push({
                type: 'dot',
            });
        }
        else if (nextChar === '(') {
            tokenStream.push({
                type: 'openParen',
            });
        }
        else if (nextChar === ')') {
            tokenStream.push({
                type: 'closeParen',
            });
        }
        else if (validSingleChars.test(nextChar)) {
            // scan ahead to read the whole identifier
            var name_1 = nextChar;
            while (/[₀-₉]/.test(str[pos + 1])) {
                pos++;
                name_1 += str[pos];
            }
            tokenStream.push({
                type: 'identifier',
                value: name_1,
            });
        }
        else if (validMultiChars.test(nextChar)) {
            var name_2 = nextChar;
            while (validMultiChars.test(str[pos + 1])) {
                pos++;
                name_2 += str[pos];
            }
            while (/[₀-₉]/.test(str[pos + 1])) {
                pos++;
                name_2 += str[pos];
            }
            tokenStream.push({
                type: 'identifier',
                value: name_2,
            });
        }
        else if (nextChar === ':') {
            pos++;
            if (str[pos] !== '=') {
                throw new errors_1.LambdaLexingError('\'=\' expected after :');
            }
            tokenStream.push({
                type: 'assignment',
            });
        }
        else {
            // TODO: associate every token with a padding, so we can get better syntax errors in the parsing stage.
            var excerptPadding = 5;
            var lower = Math.max(pos - excerptPadding, 0);
            var upper = Math.min(pos + excerptPadding, str.length);
            var excerpt = str.slice(lower, upper);
            throw new errors_1.LambdaLexingError("Unexpected character at ".concat(pos, ": ").concat(excerpt));
        }
    }
    return tokenStream;
}
exports.tokenize = tokenize;
