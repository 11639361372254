"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderExpression = void 0;
function renderExpression(expression) {
    switch (expression.type) {
        case "application":
            var leftSide = void 0;
            if (expression.left.type !== "function") {
                leftSide = renderExpression(expression.left);
            }
            else {
                leftSide = "(".concat(renderExpression(expression.left), ")");
            }
            // I have no idea whether the rendering of the right side is valid.
            var rightSide = void 0;
            if (expression.right.type === "variable") {
                rightSide = renderExpression(expression.right);
            }
            else {
                rightSide = "(".concat(renderExpression(expression.right), ")");
            }
            return "".concat(leftSide).concat(rightSide);
        case "function":
            return "\u03BB".concat(expression.argument, ".").concat(renderExpression(expression.body));
        case "variable":
            return expression.name;
    }
}
exports.renderExpression = renderExpression;
