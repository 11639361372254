"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.purgeAstCache = exports.cacheOnAst = exports.getAllArgumentNames = exports.getFreeVars = void 0;
var ramda_1 = require("ramda");
function cacheOnAst(fn) {
    var cacheSymbol = "".concat(fn.name, "_").concat(Math.random().toString().slice(2));
    return function (ast) {
        if (!ast.__cache__) {
            ast.__cache__ = {};
        }
        if (ast.__cache__[cacheSymbol] &&
            ast.__cache__[cacheSymbol].computedWith === ast) {
            return ast.__cache__[cacheSymbol].value;
        }
        else {
            var result = fn(ast);
            ast.__cache__[cacheSymbol] = {
                // if the property accidentally gets included on the wrong node (like
                // via the splat operator), this invalidates it.
                computedWith: ast,
                value: result,
            };
            return result;
        }
    };
}
exports.cacheOnAst = cacheOnAst;
// returns a new AST without the caches
function purgeAstCache(ast) {
    var newAst;
    switch (ast.type) {
        case "variable":
            newAst = ast;
            break;
        case "function":
            newAst = __assign(__assign({}, ast), { body: purgeAstCache(ast.body) });
            break;
        case "application":
            newAst = __assign(__assign({}, ast), { left: purgeAstCache(ast.left), right: purgeAstCache(ast.right) });
            break;
    }
    delete newAst.__cache__;
    return newAst;
}
exports.purgeAstCache = purgeAstCache;
// TODO: Should for consistensy change to [name]
var getFreeVars = cacheOnAst(function getFreeVarsUnmemoized(expression) {
    switch (expression.type) {
        case "variable":
            return [expression];
        case "function":
            return getFreeVars(expression.body).filter(function (token) { return token.name !== expression.argument; });
        case "application":
            var leftFree = getFreeVars(expression.left);
            var rightFree = getFreeVars(expression.right);
            return (0, ramda_1.uniqBy)(function (term) { return term.name; }, leftFree.concat(rightFree));
    }
});
exports.getFreeVars = getFreeVars;
var getAllArgumentNames = cacheOnAst(function getAllArgumentNamesUnmemoized(expression) {
    switch (expression.type) {
        case "variable":
            return [];
        case "function":
            return __spreadArray(__spreadArray([], getAllArgumentNames(expression.body), true), [expression.argument], false);
        case "application":
            var leftArgs = getAllArgumentNames(expression.left);
            var rightArgs = getAllArgumentNames(expression.right);
            return __spreadArray(__spreadArray([], leftArgs, true), rightArgs, true);
    }
});
exports.getAllArgumentNames = getAllArgumentNames;
