"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replace = exports.eReduce = exports.eReducable = exports.bReduce = exports.bReducable = void 0;
var util_1 = require("./util");
// Expression -> bool
function bReducable(exp) {
    return exp.type === "application" && exp.left.type === "function";
}
exports.bReducable = bReducable;
// We don't know whether we CAN beta reduce the term
function bReduce(expression) {
    if (!bReducable(expression)) {
        return undefined;
    }
    return replace(expression.left.argument, expression.right, expression.left.body);
}
exports.bReduce = bReduce;
function eReducable(expression) {
    if (expression.type !== "function" ||
        expression.body.type !== "application" ||
        expression.body.right.type !== "variable") {
        return false;
    }
    // --
    if (expression.body.right.name !== expression.argument) {
        return false;
    }
    var freeInF = (0, util_1.getFreeVars)(expression.body.left).map(function (token) { return token.name; });
    if (freeInF.includes(expression.argument)) {
        return false;
    }
    return true;
}
exports.eReducable = eReducable;
function eReduce(expression) {
    if (!eReducable(expression)) {
        return undefined;
    }
    return expression.body.left;
}
exports.eReduce = eReduce;
// Total garbage implementation
var replacementMapping = {
    0: "₀",
    1: "₁",
    2: "₂",
    3: "₃",
    4: "₄",
    5: "₅",
    6: "₆",
    7: "₇",
    8: "₈",
    9: "₉",
    L: "λ",
};
var replaceAll = function (str) {
    return str
        .split("")
        .map(function (letter) { return replacementMapping[letter] || letter; })
        .join("");
};
function generateNewName(freeVars) {
    var counter = 0;
    var nextName;
    do {
        counter++;
        nextName = replaceAll("ε" + counter);
    } while (freeVars.includes(nextName));
    return nextName;
}
// Replaces everything named name in expression with replacer
// Follows the rules for capture-avoiding substitutions
function replace(nameToReplace, replacer, expression) {
    switch (expression.type) {
        case "application":
            return {
                type: "application",
                left: replace(nameToReplace, replacer, expression.left),
                right: replace(nameToReplace, replacer, expression.right),
            };
        case "function":
            // shadowing
            if (nameToReplace === expression.argument) {
                return expression;
            }
            // capture avoidance
            var freeInReplacer = (0, util_1.getFreeVars)(replacer).map(function (node) { return node.name; });
            var alphaSafeExpression = expression;
            if (freeInReplacer.includes(expression.argument)) {
                // Then we pick a new name that
                //  1: isn't free in the replacer
                //  2: isn't free in the expression body
                //  3: isn't captured by an intermediate function in the expression body
                var freeInExpressionBody = (0, util_1.getFreeVars)(expression.body).map(function (node) { return node.name; });
                var argNames = (0, util_1.getAllArgumentNames)(expression.body);
                var newName = generateNewName(freeInReplacer.concat(freeInExpressionBody, argNames));
                // And make that the new function arg name
                alphaSafeExpression = {
                    type: "function",
                    argument: newName,
                    body: replace(expression.argument, { type: "variable", name: newName }, expression.body),
                };
            }
            return {
                type: "function",
                argument: alphaSafeExpression.argument,
                body: replace(nameToReplace, replacer, alphaSafeExpression.body),
            };
        case "variable":
            return expression.name === nameToReplace ? replacer : expression;
    }
}
exports.replace = replace;
