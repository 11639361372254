"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cannonize = void 0;
var operations_1 = require("./operations");
var util_1 = require("./util");
// Deterministically renames all variables in an expression
// such that if there exists an alpha conversion between two ASTs,
// the cannonized asts are identical
function cannonizeUnmemoized(ast) {
    var count = 0;
    return rCannonize(ast);
    function generateNewName() {
        count++;
        return "[_c".concat(count, "]");
    }
    function rCannonize(a) {
        switch (a.type) {
            case "variable":
                return a;
            case "application":
                return {
                    type: "application",
                    left: rCannonize(a.left),
                    right: rCannonize(a.right),
                };
            case "function":
                var newName = generateNewName();
                return {
                    type: "function",
                    argument: newName,
                    body: rCannonize((0, operations_1.replace)(a.argument, { type: "variable", name: newName }, a.body)),
                };
        }
    }
}
exports.cannonize = (0, util_1.cacheOnAst)(cannonizeUnmemoized);
